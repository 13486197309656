<template>
  <div class="global-container ">
    <NuxtPwaManifest />
    <AppSidebar v-if="$breakpoints.lSm" />
    <div
      class="main-container dark:bg-gray-800 bg-slate-100"
      :class="{
        'sidebar--large': !$store.state.ui.isSidebarMini,
        'sidebar--mini': $store.state.ui.isSidebarMini,
      }"
    >
      <AppNavbar />
      <main>
        <slot />
      </main>
      <AppMobileFooter v-if="$breakpoints.sMd" />
    </div>
    <UNotifications class="w-full mb-16" />
    <USlideovers />
  </div>
</template>

<style lang="scss">
.global-container {
  display: flex;
  flex-flow: row nowrap;
  // background-color: $bg-color-primary;
  height: 100%;
  margin: 0 auto;
}

.main-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  // background-color: $bg-color-secondary;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  transition: margin-left 0.8s ease-in;
  margin-left: 0px;

  @include breakpoint_up(md) {
    &.sidebar--large {
      margin-left: 239px;
    }

    &.sidebar--mini {
      margin-left: 96px;
    }
  }

  @include breakpoint_up(xl) {
    &.sidebar--large {
      margin-left: 255px;
    }

    &.sidebar--mini {
      margin-left: 112px;
    }
  }
}

main {
  width: 100%;
  padding: 20px;
  background-color: inherit;
  margin-top: 104px;
  padding-bottom: 84px;

  @include breakpoint_up(md) {
    padding: 24px;
    margin-top: 70px;
  }

  @include breakpoint_up(xl) {
    padding: 32px;
  }
}
</style>
